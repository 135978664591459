<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік договорів
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            :main="{ component: 'ContractLegacy', title: 'Договори' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{
                      flat_id: flat.id,
                      flat_name: flat.full_address,
                    }"
            @updateItemModal="updateItemModal"
        ></MainModal>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="tableHeaders"
        :items="contracts"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'ContractLegacy', title: 'Договори' }"
            :button="{ iconActivator: true, class: 'mr-2', color: getAccountIcon(item.service_icon, 'color'), buttonIcon: getAccountIcon(item.service_icon, 'icon'), iconSize: 26 }"
            :item="{
                      id: item.id,
                      flat_id: flat.id,
                      date_start: item.date_start,
                      date_close: item.date_close,
                      number: item.number,
                      service_id: item.service_id,
                      contract_name: item.contract_name,
                      prolonged: item.prolonged,
                      flat_name: flat.full_address,
                      tax_by_pay: item.tax_by_pay
                    }"
            :modal="dialog_id === item.id"
            @updateItemModal="updateItemModal"
        />
      </template>
      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_close="{ item }">
        <span>
          {{ item.date_close | formatDate }}
        </span>
      </template>
      <template v-slot:item.prolonged="{ item }">
        <v-simple-checkbox v-model="item.prolonged" disabled :ripple="false"/>
      </template>
      <template v-slot:item.tax_by_pay="{ item }">
        <v-simple-checkbox v-model="item.tax_by_pay" disabled :ripple="false"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from "vuex";
import {GET_CONTRACT_LEGACY_BY_FLAT} from "@/store/actions/contract";
import {getAccountIcon} from "@/utils/icons";

export default {
  name: "HWL_FlatContract",
  props: {},
  components: { MainModal },
  data() {
    return {
      dialog_id: 0,
      tableHeaders: [
        { text: '', value: 'icon', width: 20 },
        { text: 'Найменування', value: 'contract_name' },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Пролонгований', value: 'prolonged' },
        { text: 'ПДВ по оплаті', value: 'tax_by_pay' },
        { text: '№ договору', value: 'number' },
        { text: 'Дата початку', value: 'date_start' },
        { text: 'Дата завершення', value: 'date_close' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      contracts: 'getLegacyContracts',
      flat: 'getLegacyFlat'
    })
  },
  methods: {
    ...mapActions({
      fetch_contracts: GET_CONTRACT_LEGACY_BY_FLAT
    }),
    getAccountIcon,
    updateItemModal() {
      this.dialog_id = 0
    },
    openModal(row) {
      this.dialog_id = row.id
    },
  },
  created() {
    this.fetch_contracts(this.flat.id)
  }
}
</script>

<style scoped lang="scss">

</style>