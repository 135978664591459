var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Перелік договорів ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'ContractLegacy', title: 'Договори' },"button":{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' },"item":{
                    flat_id: _vm.flat.id,
                    flat_name: _vm.flat.full_address,
                  }},on:{"updateItemModal":_vm.updateItemModal}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.contracts,"hide-default-footer":""},on:{"click:row":_vm.openModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'ContractLegacy', title: 'Договори' },"button":{ iconActivator: true, class: 'mr-2', color: _vm.getAccountIcon(item.service_icon, 'color'), buttonIcon: _vm.getAccountIcon(item.service_icon, 'icon'), iconSize: 26 },"item":{
                    id: item.id,
                    flat_id: _vm.flat.id,
                    date_start: item.date_start,
                    date_close: item.date_close,
                    number: item.number,
                    service_id: item.service_id,
                    contract_name: item.contract_name,
                    prolonged: item.prolonged,
                    flat_name: _vm.flat.full_address,
                    tax_by_pay: item.tax_by_pay
                  },"modal":_vm.dialog_id === item.id},on:{"updateItemModal":_vm.updateItemModal}})]}},{key:"item.date_start",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}},{key:"item.date_close",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_close))+" ")])]}},{key:"item.prolonged",fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":"","ripple":false},model:{value:(item.prolonged),callback:function ($$v) {_vm.$set(item, "prolonged", $$v)},expression:"item.prolonged"}})]}},{key:"item.tax_by_pay",fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"disabled":"","ripple":false},model:{value:(item.tax_by_pay),callback:function ($$v) {_vm.$set(item, "tax_by_pay", $$v)},expression:"item.tax_by_pay"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }